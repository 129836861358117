@import "GlobalStyles/functions";

.scrollableContainer {
  scroll-behavior: smooth;
}

.modalContainer {
  @include for-desktop-only {
    padding: 3rem;
  }
  @include for-mobile-only {
    padding: 1rem;
  }
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  margin: auto;
}
.modalSheet {
  width: 90%;
  max-height: 90%;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.modalContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  overflow: auto;

}
