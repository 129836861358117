$light_green : #A5CEA1;
$appbar_link: #FFFFFF;
$basic_black : #333333;
$lighter_yellow : #FFE2AC;
$light_orange : #FF9A7B;
$phoneWidth: 640px;
$citiesBackground: #fffffc;
$labelBackground: #ffffff;
$textFieldBackground:#ffffff;
$textFieldAnimatedBackground: #10ff10;
