.container {
  display: flex;
  flex: 1;
  position: relative;

  .spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
}
