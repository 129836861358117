.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex: 1;
  .topButtons {
    display: flex;
    gap: 1rem;
    flex-wrap:wrap;
  }
}
