@import "functions";

.inARow {
  @include for-desktop-only {
    display: flex;
    column-gap: 1rem;
    & > * {
      flex: 1;
    }
  }
  @include for-mobile-only {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}
.notOnMobile:not(#\09) {
  @include for-mobile-only {
    display: none;
  }
}

:global(.MuiButton-startIcon):not(#\09) {
  margin:0 -4px 0 8px;
}
