@import "GlobalStyles/variables";
@import "GlobalStyles/functions";

.container {
  column-gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  direction: ltr;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin: 1rem;
    flex: 1;

    & > * {
      flex: 1;
      & > div > input:read-only {
        color:var(--joy-palette-text-disabled);
      }
    }
    .checkBoxStyleAssurer:not(#\09) {
      margin: 0.1px;
      &>*:first-child {
        margin-left: 0.5rem;
        margin-right: 0;
      }
    }
  }

}
.footer {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  direction: rtl;
}
.confirmRow {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  transition: opacity 3s;
  opacity: 1;
}
.confirmRowStart {
  display: flex;
  opacity: 0;
}
.confirmRowHidden {
  display: none;
  opacity: 0;
}
.actionsRow {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  transition: opacity 3s;
  opacity: 1;
}
.actionsRowStart {
  display: flex;
  opacity: 0;
}
.actionsRowHidden {
  display: none;
  opacity: 0;
}
.oneRow:not(#\09) {
  display: flex;
  flex-direction: row;
}
