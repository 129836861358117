.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  flex: 1;
  .topButtons {
    display: flex;
    gap: 1rem;
    flex-wrap:wrap;
  }
  .middle {
    display: flex;
    gap: 1rem;
    flex-wrap:wrap;

  }
  .bottomButtons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap:wrap;
    align-items: flex-start;
    & > div {
      width: 100%;
    }
  }
  .checkbox {
    display: flex;
    gap: 1rem;
    flex-wrap:wrap;
  }
}
.modal {
  max-width: 30rem;
  margin:auto;
}
.checkBoxStyleAssurer:not(#\09) {
  margin: 0.1px;
  &>*:first-child {
    margin-left: 0.5rem;
    margin-right: 0;
  }
}
.error {
  border: 3px solid red;
}
