.contentMain {
  flex:1;
  .appBar {
    direction: rtl;
  }
  .waiting {
    text-align: center;
    margin-top:10rem;
    font-size: 30px;
  }
}
.swaggerContent {
  direction: ltr;
}
