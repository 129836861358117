@import 'GlobalStyles/functions';
@import 'GlobalStyles/classes';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;

  & > form {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem 0;
  }
}
.ws1results {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin:1rem;
  padding: 1rem;
  overflow: hidden;
  row-gap: 1rem;

  & > div:first-child {
    font-size: 20px;
    text-align: center;
    padding: 1rem;
  }

  & > div:not(:first-child) {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

    & > div:first-child {
      flex: 1;
    }
    & > div:last-child {
      flex: 2;
      font-weight: bold;
    }
  }
  .good {
    background-color: var(--joy-palette-success-300);
    color: var(--joy-palette-success-contrastText);
  }
  .bad {
    background-color: var(--joy-palette-error-main);
    color: var(--joy-palette-error-contrastText);
  }
}

.footer {
  display: flex;
  flex: 1;
  & > * {
    flex: 1;
  }
}
