@import "GlobalStyles/classes";

.tableContainerHolder {
  display: flex;
  flex: 1;
  & th, & td {
    text-align: right;
    cursor: pointer;
  }
  @include for-mobile-only {
    max-height: 50vh;
  }
  @include for-desktop-only {
    max-height: 60vh;
  }
}

.footer {
  display: flex;
  justify-content: center;
}
