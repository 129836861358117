@import 'GlobalStyles/variables';

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  direction: ltr;
}

.clickableLinks {
  &:not(#\9) {
    color: $appbar_link;
  }
}
