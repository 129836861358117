@import "variables";
@mixin gradientColor($grad) {
  background: $grad;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin for-mobile-only {
  @media screen and (max-width: $phoneWidth) {
    @content;
  }
}

@mixin for-desktop-only {
  @media not (max-width: $phoneWidth) {
    @content;
  }
}

@mixin overflowEllipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@mixin multiLineElilipses($lines, $maxHeight){
  max-height: $maxHeight;
  overflow: hidden;
  -webkit-line-clamp: $lines;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@mixin lightBoxShadow{
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

@mixin black{
  filter: brightness(0%);
}

@mixin opacityTransition {
  -o-transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out;
}

@mixin capitalise {
  text-transform:  lowercase;
  &::first-letter{
    text-transform: uppercase;
  }
}
