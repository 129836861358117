@import 'GlobalStyles/functions';
@import 'GlobalStyles/classes';

.ticketEditorContainer {
    display: flex;
    flex: 1;

    &>div {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 1rem;
      margin: 1rem 0;
      & > form {
        display: flex;
        flex: 1;
        flex-direction: column;
        row-gap: 1rem;
      }
    }
  }
.checkBoxStyleAssurer:not(#\09) {
  margin: 0.1px;
  &>*:first-child {
    margin-left: 0.5rem;
    margin-right: 0;
  }
}

.results {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 13rem;
  margin:1rem;
  padding: 1rem;
  overflow: hidden;
  row-gap: 1rem;

  & > div:first-child {
    font-size: 20px;
    text-align: center;
    padding: 1rem;
  }

  & > div:not(:first-child) {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

    & > div:first-child {
      flex: 1;
    }
    & > div:last-child {
      flex: 2;
      font-weight: bold;
    }
  }
  .good {
    background-color: var(--joy-palette-success-300);
    color: var(--joy-palette-success-contrastText);
  }
  .bad {
    background-color: var(--joy-palette-error-main);
    color: var(--joy-palette-error-contrastText);
  }
}
.ticketButtons {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  gap: 1rem;
}
