.spinnerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.spinnerButton {
  min-width: 10rem;
}
.progress {
  color: var(--joy-palette-success-softDisabledBg);
  position: absolute;
}
