@import 'GlobalStyles/classes';

.retrievalContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 13rem;
  overflow: hidden;
  row-gap: 1rem;

  & > div:not(.inARow) {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

    & > div:first-child {
      flex: 1;
    }
    & > div:last-child {
      flex: 2;
      font-weight: bold;
    }
  }
}
