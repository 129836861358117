@import "GlobalStyles/functions";

$back_btn_size: 3rem;
.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url("/Static/Images/Backgrounds/geobez.svg");
  background-repeat: no-repeat;
  background-size: cover;
  .header {
    display: flex;
    flex: 1;
    width: 100%;
    max-height: 5rem;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .content {
    display: flex;
    flex: 1;
    width: 100%;
    color: $basic_black;

    & > div {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      & > div {
        display: flex;
        flex: 1;
        justify-content: center;
        align-content: center;
        flex-direction: column;
      }
    }
  }
  .footer {
    display: flex;
    flex: 1;
    width: 100%;
    max-height: 5rem;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}



