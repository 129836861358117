@import "GlobalStyles/functions";

.orderPageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;

  & > .orderHeaderContainer {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    & > h3 > span {
      font-size: 15px;
      font-weight: 300;
    }
  }
}

.topButtonsPanel {
  column-gap: 1rem;
  display: flex;
}

.footer {
  justify-content: center;
  display: flex;
  padding-top: 1rem;
  position: relative;
  column-gap: 3rem;
}
.footerGis {
  justify-content: center;
  display: flex;
  padding-top: 1rem;
  position: relative;
  row-gap: 1rem;
  column-gap: 3rem;

  & > div {
    display: flex;
    column-gap: 3rem;
    justify-content: center;
  }
  @include for-mobile-only {
    flex-direction: column;
  }
  .smallButtons {
    @include for-desktop-only {
      display: contents;
    }
    .locateButton {
      @include for-desktop-only {
        order: 1;
      }
    }
    .clearFormButton {
      @include for-desktop-only {
        order: 3;
      }
    }
  }
  .continueButton {
    @include for-desktop-only {
      order: 2;
    }
  }
}
