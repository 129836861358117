
.loginLogoutBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  row-gap: 1rem;

  & > div {
    display: flex;
    flex: 1;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  & > h1 {
    text-align: center;
  }

}
