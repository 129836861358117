@import "GlobalStyles/variables";
@import "GlobalStyles/functions";

.container {
  column-gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
  }
}


.inputRow {
  display: flex;
  align-items: center;
  row-gap: 0.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;

  & > * {
    flex: 1;
  }
}

.first_row {
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  p:first-child {
    margin-right: 12px;
  }
}

.airgap {
  padding: 1rem;
  margin: 1rem;
  direction: ltr;
  min-width: 200px;
  display: flex;
  margin-bottom: 2rem;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    & > * {
      flex: 1;
    }
  }
}
