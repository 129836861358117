.ticketPageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;

  &>.ticketHeaderContainer {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    & > span {
      font-size: 15px;
      font-weight: 300;
    }
  }
}
.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  justify-content: center;
  display: flex;
  padding-top: 1rem;
  position: relative;
  column-gap: 3rem;
}
