@import "GlobalStyles/variables";
@import "GlobalStyles/functions";

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url("/Static/Images/Backgrounds/geobez.svg");
  background-repeat: no-repeat;
  background-size: cover;
  direction: rtl;
  overflow:auto;
  scroll-behavior: smooth;

  .header {
    display: flex;
    flex: 1;
    width: 100%;
    max-height: 4rem;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    & > header {
      flex: 1;
      justify-content: center;
    }
  }
  .content {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 1240px;
    color: $basic_black;
  }
  .footer {
    display: flex;
    flex: 1;
    width: 100%;
    max-height: 5rem;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}

