.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;

  & > .headerContainer {
    display: flex;
    flex: 0 1;
    flex-direction: column;
    row-gap: 1rem;
    margin-bottom: 1rem;

    & > h3 > span {
      font-size: 15px;
      font-weight: 300;
    }
  }
}

.topButtonsPanel {
  column-gap: 1rem;
  display: flex;
}

.footer {
  justify-content: center;
  display: flex;
  padding-top: 1rem;
  position: relative;
  column-gap: 3rem;
}

.formEditorContainer {
  display: flex;
  flex: 1;

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem;
    margin: 1rem 0;

    & > form {
      display: flex;
      flex: 1;
      flex-direction: column;
      row-gap: 1rem;
    }
  }
}

.resultsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 13rem;
  margin:1rem 0;
  padding: 1rem 1rem 4rem 1rem;
  overflow: hidden;
  //flex-wrap: wrap;
  row-gap: 1rem;

  & > div:first-child {
    font-size: 20px;
    text-align: center;
    padding: 1rem;
  }

  & > div:not(:first-child) {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

    & > div:first-child {
      flex: 1;
    }
    & > div:last-child {
      flex: 2;
      font-weight: bold;
    }
  }
  .good {
    background-color: var(--joy-palette-success-300);
    color: var(--joy-palette-success-contrastText);
  }
  .bad {
    background-color: var(--joy-palette-error-main);
    color: var(--joy-palette-error-contrastText);
  }
}
