.label {
  color: var(--joy-palette-text-primary);
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 1rem;
  line-height: 1.4375em;
  font-weight: 400;
  background-color: var(--joy-palette-FilledInput-bg);
  padding-top: 25px;
  padding-left: 12px;
  padding-bottom: 8px;
  padding-right: 12px;
}
