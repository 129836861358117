@import 'GlobalStyles/classes';

.orderEditorContainer {
    display: flex;
    flex: 1;

    &>div {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 1rem;
      margin: 1rem 0;
      & > form {
        display: flex;
        flex: 1;
        flex-direction: column;
        row-gap: 1rem;
      }
    }
  }
.checkBoxStyleAssurer:not(#\09) {
  margin: 0.1px;
  &>*:first-child {
    margin-left: 0.5rem;
    margin-right: 0;
  }
}
.orderButtons {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  gap: 1rem;
}
.signatureDiv {
  margin-top: 1rem;
  display: flex;
}
