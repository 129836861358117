@import "GlobalStyles/functions";

.retrievalContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 13rem;
  @include for-desktop-only {
    margin: 1rem;
  }
  overflow: hidden;
  row-gap: 1rem;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

    & > div:first-child {
      flex: 1;
    }
    & > div:last-child {
      flex: 2;
      font-weight: bold;
      @include for-mobile-only {
        text-align: left;
      }
    }
  }
}

.Ws14Container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top:1rem;
  row-gap: 1rem;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

    & > div:first-child {
      flex: 1;
    }
    & > div:last-child {
      flex: 2;
      font-weight: bold;
      @include for-mobile-only {
        text-align: left;
      }
    }
  }
}
