@import "GlobalStyles/variables";
@import "GlobalStyles/functions";

:global(.Toastify__toast):not(#\9) {
  direction: rtl;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

:global(.Toastify__toast-body):not(#\9) {
  display: flex;
  flex: 1;
}

:global(.Toastify__toast--success):not(#\9) {
  background-color: var(--joy-palette-success-100);
  color: var(--joy-palette-success-900);
}

:global(.Toastify__toast--error):not(#\9) {
  background-color: var(--joy-palette-warning-100);
  color: var(--joy-palette-warning-900);
}

:global(.Toastify__toast--info):not(#\9) {
  background-color: var(--joy-palette-neutral-100);
  color: var(--joy-palette-neutral-900);
}

:global(.Toastify__progress-bar--success):not(#\9){
  background-color: $light_green;
}

:global(.Toastify__progress-bar--error):not(#\9){
  background-color: $light_orange;
}

:global(.Toastify__progress-bar--info):not(#\9){
  background-color: $lighter_yellow;
}

.toastr{
  display: flex;
  flex: 1;
  row-gap: 0.5rem;
}

.toastrCont {
  border-radius: 20px;
  padding: 1rem;
}

.title{
  font-size: 14px;
  font-weight: 500;
}
